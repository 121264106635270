<template>
  <div class="row">
    <div class="col-md-12" v-if="servicio.estado != 'Finalizado'">
      <div class="callout callout-warning">
        <div class="ribbon_cobrada ribbon-top-right" v-if="facturado"><span>{{$t('general.facturado')}}</span></div>
        <h5>{{$t('general.albaran')}} <strong>{{albaran.albaran.numero_albaran}}</strong></h5>
        <div class="row" style="margin: 0; gap:5px">
          <strong>Cliente: </strong>
          {{ albaran.albaran.nombre_cliente }}
        </div>
        <div v-if="albaran.numero && albaran.numero!=0" class="row" style="margin: 0; gap:5px">
          <strong>Nº Factura: </strong>
          {{ albaran.numero }}
        </div>
        <div class="row" style="margin-top: 15px;">
          <div class="col-md-4"><input type="date" class="form-control input-xs"
              :value="fechaing(albaran.albaran.fecha_hoy)" readonly></div>
          <div class="col-md-3">
            <h4>{{albaran.albaran.total}}&euro;</h4>
          </div>
          <div class="col-md-5 btn-group">
            <button type="button" class="btn btn-default" @click="abrirpdf()"><i
                class="fas fa-file-invoice"></i></button>
            <button type="button" class="btn btn-default" v-if="eliminable" @click="eliminarfactura=!eliminarfactura"
              title="Eliminar"><i class="fas fa-times"></i></button>
          </div>
        </div>
        <div class="input-group input-group-sm" v-if="eliminarfactura==true"
          style="margin-top: 20px; margin-bottom: 0px;">
          <input type="text" class="form-control" placeholder="Motivo" v-model="motivoeliminacion">
          <div class="input-group-append">
            <button type="button" class="btn btn-danger" @click="eliminar()" title="Eliminar">Eliminar</button>
          </div>
        </div>
      </div>
    </div>


    <div class="col-md-12" v-if="servicio.estado == 'Finalizado'">
      <div class="callout callout-warning">
        <div class="ribbon_cobrada ribbon-top-right" v-if="facturado"><span>{{ $t('general.facturado') }}</span></div>
        <h5>{{ $t('general.albaran') }} <strong>{{ albaran.albaran.numero_albaran }}</strong>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;<strong style="font-size:medium">Cliente:
            </strong>
            {{ albaran.albaran.nombre_cliente }}</span>
        </h5>
        <div v-if="albaran.numero && albaran.numero != 0" class="row" style="margin: 0; gap:5px">
          <strong>Nº Factura: </strong>
          {{ albaran.numero }}
        </div>
        <div class="row" style="margin-top: 15px;">
          <div class="col-md-2"><input type="date" class="form-control input-xs"
              :value="fechaing(albaran.albaran.fecha_hoy)" readonly></div>
          <div class="col-md-2" style="display: flex; justify-content: flex-end;">
            <h4>{{ albaran.albaran.total }}&euro;</h4>
          </div>
          <div class="col-md-8" style="display:flex;justify-content:flex-end;">
            <button type="button" class="btn btn-default" @click="abrirpdf()"><i
                class="fas fa-file-invoice"></i></button>
            <button type="button" class="btn btn-default" v-if="eliminable" @click="eliminarfactura = !eliminarfactura"
              title="Eliminar"><i class="fas fa-times"></i></button>
          </div>
        </div>
        <div class="input-group input-group-sm" v-if="eliminarfactura == true"
          style="margin-top: 20px; margin-bottom: 0px;">
          <input type="text" class="form-control" placeholder="Motivo" v-model="motivoeliminacion">
          <div class="input-group-append">
            <button type="button" class="btn btn-danger" @click="eliminar()" title="Eliminar">Eliminar</button>
          </div>
        </div>
      </div>
    </div>







  </div>
</template>
<style scoped>
/* common */
.ribbon_cobrada {
  width: 90px;
  height: 90px;
  overflow: hidden;
  position: absolute;
}
.ribbon_cobrada::before,
.ribbon_cobrada::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}
.ribbon_cobrada span {
  position: absolute;
  display: block;
  width: 165px;
  padding: 5px 0;
  background-color: #2980b9;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 10px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
  z-index: 20;
}

.ribbon_no_cobrada {
  width: 90px;
  height: 90px;
  overflow: hidden;
  position: absolute;
}
.ribbon_no_cobrada::before,
.ribbon_no_cobrada::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #c42020;
}
.ribbon_no_cobrada span {
  position: absolute;
  display: block;
  width: 165px;
  padding: 5px 0;
  background-color: #d32c42;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 10px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
  z-index: 20;
}


/* top right*/
.ribbon-top-right {
  top: -8px;
  right: -1px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}


</style>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';

export default {
props: ['id', 'servicio', 'albaran','togglecargar', 'eliminable','servicioext'],

    data() {
        return {
          mostrarModulo: false,
          datosurl: "",
          url: "",
          motivo: "Prueba",
          motivoeliminacion: "",
          destino: "Destino",
          eliminarfactura: false,
          facturado:false,
        }
    },
    methods: {
      fechaing(fecha) {
          const [dia, mes, año] = fecha.split("/");
          return `${año}-${mes.padStart(2, '0')}-${dia.padStart(2, '0')}`;
      },
      fechaesp(fecha) {
          const [año, mes, dia] = fecha.split("-");
          return `${dia}/${mes}/${año}`;
      },
      cargardatos() { 
        console.log('alban', this.albaran);
        if(this.albaran.albaran.cobrada_factura == 'SI')this.facturado = true;
        this.url = this.$props.albaran.url_completa_albaran_pdf;
        this.datosurl = this.url;
      },
      async eliminar() { 
       //DELETE  modulos/pwgsapi/index.php/facturas/:id_factura/eliminar-factura
       if(this.motivoeliminacion == ''){
          alert('Se debe indicar un motivo.')
          return;
        }else{
          const api = new PwgsApi;
          let subidadatos = { motivo: this.motivoeliminacion};
          console.log('elimina',this.motivoeliminacion);
          await api.deletebody('facturas/' + this.$props.albaran.albaran.id_factura, subidadatos);
          this.$parent.cargardatosAlbaran();
        }
      },
      abrirpdf() {
        window.open(this.datosurl, '_blank');

      }
  },
  mounted() {
   // let aux = localStorage.getItem('direccion');
    this.datosurl = "";
    this.cargardatos();
  },
  watch: {
    togglecargar() {
      this.cargardatos();
    }
  }
}
</script>